#quiz-wrap {
  .v13 {
    .sd-container-modern {
      margin-top: 0px!important;
    }
    .sd-progress {
      margin-top: 1em;
    }
    .sd-body__progress--top {
      top: 10px!important;
    }

    .sd-page {
      text-align: left;
      justify-content: center;

      .sd-description {
        text-align: left;
      }

      .sd-item {
        padding: 0 0 8px 0;
      }

      .sd-page__description {
        width: 87%;
      }

      .sd-element__title .sv-string-viewer {
          font-family: Avenir;
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 52px; /* 130% */
          letter-spacing: -0.8px;
       }
       .sd-item__control-label {
          text-align: center;
          font-family: Avenir;
          font-size: 20px;
          font-style: normal;
          font-weight: 500;
          text-transform: capitalize;
       }

       .sd-page__title {
          font-family: Avenir;
          font-size: 15px;
          font-style: normal;
          font-weight: 900;
          line-height: 40px;
          letter-spacing: -0.28px;
          text-transform: uppercase;
          text-align: left;
          width: 87%;
       }

       [data-name="gender"] .sd-imagepicker .sd-imagepicker__item:nth-last-child(1) .sd-imagepicker__image-container {
        display: none;
       }

       .sd-imagepicker {
        grid-template-columns: auto!important;
       }
    }

    @media (max-width: 500px) {

      .sd-page {

        .sd-element__title .sv-string-viewer {
          font-family: Avenir;
          font-size: 32px;
          line-height: 40px; /* 125% */
          letter-spacing: -0.64px;
         }


      }

    }

  }
}
