:root {
    --color_shape_blue: #3078eb;
    --color_white: #ffffff;
    --color_soft_black: #999999;
    --color_medium_gray: #dfe2e6;
    --color_black: #0b090a;
    --color_dark_black: #6E757C;
    --color_light_dark_black: #8c8c91;
    --color_light_gray: #f6f6f6;
    --color_back_gray: #e5e5e5;
    --color_quiz_background_body: #f8f9fa;
    --color-primary_light_blue: #eaf2fd;
    --color-primary_lighter_blue: #edf3ff;
    --color_primary_grays: #aeb5bc;
    --color_support_gray: #dfe2e6;
    --color_dark_royal_blue: #0036a5;
    --color_shape_dark_blue: #00072d;
    --color_shape_light_dark_blue: #1A2042;
    --color_shape_mid_blue: #0a2473;
    --color_gray: #f6f6f6;
    --color_support_light_grey: #adb5bd;
    --color_dark_grey: #c0c0c0;
    --color_mid_grey: #f0f0f0;
    --color_natural_gray: #f2f2f2;
    --color_med_light_grey: #dfe2e6;
    --color_primary-red: #fceced;
    --color_Secondary-Red: #f65a5b;
    --color_dark_red: #e35256;
    --color_jasper_red: #dd3f4a;
    --color_light_grey: #e5e5e5;
    --color_Black: #000;
    --color_almost_black: #0b090a;
    --color_Support-Basically-Black: #1a1a1a;
    --color_Support-Cool-Gray: #292f36;
    --color_Support-Gray: #0b090a;
    --color_Support-Soft-Black: #999;
    --color_main-Teal: #16999b;
    --color_IOS-Blue: #007aff;
    --color_mint: #3ed1b0;
    --color_saffron: #eac435;
    --color_gray-70: rgba(41, 47, 54, 0.7);
    --color_Facebook: #395185;
    --color_CheckedIcon: #2eb40a;
    --color_Tints-Shades-Blue-Dark: #0b516a;
    --color_Main-Dark: #0d5c5d;
    --color_Main-Mid-02: #127a7c;
    --color_Tints_Shades-Red-Mid-02: #c54849;
    --color_suport-cool_blue: #00072d;
    --color_support_light_grays: #aeb5bc;
    --color_support_med_light_blue: #c1d7f9;
    --color_support_med_light_gray: #ededed;
    --color_support_med_gray: #646669;
    --color_support_light_blue: #eaf2fd;
    --color-quiz_PrimaryGrays: #aeb5bc;
    --color-quiz_primaryBlue: #4586ed;
    --color_support_gray_black: #212529;
    --color_support_saffron: #FFCB05;

    --black: rgba(0, 0, 0, 1);
    --stratos: rgba(0, 7, 45, 1);
    --international-klein-blue: rgba(0, 54, 165, 1);
    --cod-gray: rgba(11, 9, 10, 1);
    --bunting: rgba(26, 32, 66, 1);
    --blue: rgba(48, 120, 235, 1);
    --tropical-blue: rgba(193, 215, 249, 1);
    --salmon-pearl: rgba(221, 63, 74, 1);
    --saffron: rgba(234, 196, 53, 1);
    --solitude: rgba(234, 242, 253, 1);
    --alabaster: rgba(248, 249, 250, 1);
    --fair-pink: rgba(252, 236, 237, 1);
    --white: rgba(255, 255, 255, 1);
    --light-blue: rgba(87, 131, 244, 0.50);
    --light-pink: rgba(225, 225, 225, 0.20);

    --linear-blue-shade: linear-gradient(180deg, #3078EB 0%, #ACD4FD 100%), #8FC5FE;
    --linear-light-blue-shade: linear-gradient(96deg, rgba(230, 235, 246, 0.40) 6.57%, rgba(230, 235, 246, 0.15) 100.79%);

    --font-size-xs: 10px;
    --font-size-s: 12px;
    --font-size-m: 18px;
    --font-size-l: 20px;
    --font-size-xxxl: 48px;
    --font-size-l2: 24px;
    --font-size-xl2: 32px;
    --font-size-xxl2: 40px;

    --font-family-avenir-black: "Avenir-Black";
    --font-family-avenir-book: "Avenir-Book";
    --font-family-avenir-heavy: "Avenir-Heavy";
    --font-family-avenir-medium: "Avenir-Medium";
    --font-family-avenir-regular: "Avenir-Regular";
    --font-family-avenir-roman: "Avenir-Roman";
  }

  /* Classes */


