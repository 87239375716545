#quiz-wrap {
  #surveyContainer {
    .custom_navigation {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      gap: 20px;
      border-top: 1px solid var(--color_med_light_grey);
      padding-top: 1em;

      &-previous {
        display: flex;
        padding: 1em 2em;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        border: none;
        color: var(--color_Black);
        background-color: transparent;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      &-previous:hover {
        border-radius: 16px;
        background-color: var(--color_white);
      }

      &-next {
        display: flex;
        padding: 1em 2em;
        justify-content: center;
        align-items: center;
        border-radius: 16px;
        background: var(--color-quiz_primaryBlue);
        border: none;
        color: #fff;
        gap: 5px;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      &-next:hover{
        background: var(--color_shape_blue);
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }

      @media (max-width: 768px) {
        position: fixed;
        padding: .5em;
        margin: 0 1em;
        bottom: 0px;
        width: 100vw;
        left: -16px;
        background: var(--color_quiz_background_body);
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
      }
    }

    .bg-wrap {
      border-radius: 24px;
      background: var(--color_shape_light_dark_blue);
      padding: 1em;
    }

    .info_text {
      font-family: "Avenir Black";
      font-style: normal;
      font-weight: 900;
      font-size: 1.8rem;
      line-height: 33px;
      text-align: center;
      color: var(--color_quiz_Black);
    }

    .fairy_navigation {
      position: fixed;
      padding: .5em;
      margin: 0 1em;
      bottom: 0px;
      width: 100vw;
      left: -16px;
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      background: transparent;
      border: none;
    }

    .fairy_info_slide {
      border-radius: 15px;
      border: 1px solid var(--light-blue);
      background: var(--linear-light-blue-shade);
      box-shadow: 0px 4px 24px -1px var(--light-pink);
      backdrop-filter: blur(7.5px);
      padding: 5%!important;
      @media (max-width: 768px) {
        padding: 10%!important;
        margin: 5%;
      }
    }
    .info_slide_text {
      color: var(--color_dark_royal_blue);
      font-family: Avenir;
      font-size: 32px;
      font-style: normal;
      font-weight: 800;
      line-height: 52px;
      letter-spacing: -0.8px;
      text-align: start;

      @media (max-width: 768px) {
        font-size: 24px;
        line-height: 32px;
        letter-spacing: -0.64px;
      }
    }
    .info_slide_result {
      color: var(--color_white);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Avenir;
      font-size: 24px;
      font-style: normal;
      font-weight: 900;
      line-height: normal;
      letter-spacing: -0.64px;
    }

    .svgCornerBL{
      position: absolute;
      bottom: -64px;
      left: -59px;
      width: 24%;
      -ms-transform: rotate(0deg); /* IE 9 */
      -webkit-transform: rotate(0deg); /* Chrome, Safari, Opera */
      opacity: 0.7;
      transform: rotate(0deg);
      @media (max-width: 768px) {
        left: -20px;
      }
    }

    .svgCornerBR {
      position: absolute;
      bottom: -50px;
      right: -11px;
    }

    .svgCornerTL{
      position: absolute;
      top: -50px;
      right: -20px;
      width: 70px;
    }

    .svgCornerTR{
      position: absolute;
      top: 0;
      left: 0;
      -ms-transform: rotate(90deg); /* IE 9 */
      -webkit-transform: rotate(90deg); /* Chrome, Safari, Opera */
      transform: rotate(90deg);
    }

    .result_page {
      background: var(--linear-blue-shade)!important;
      display:flex;
      justify-content:center;
      padding:0!important;
      flex-direction: column;
      align-items: center;


      img {
        width: 100px;
      }
    }

    .custom-postSubText {
      font-family: "Avenir Book";
      padding: 16px;
      background: var(--color_white);
      border: 1px solid var(--color_support_gray);
      box-sizing: border-box;
      border-radius: 8px;
      line-height: 22px;
      font-size: 1.15em;
      text-align: left;
      font-weight: 500;
      overflow: auto;
      clear: both;
    }

    .margin-top {
      margin-top: 1rem;
    }

    .policyText,
    .policyText a {
      color: var(--color_quiz_Black);
      font-family: Avenir Medium;
    }

    .policyText {
      font-style: italic;
      font-size: 12px;
      line-height: 16px;
      text-transform: capitalize;
      margin: 0 auto;
      padding: 0 25px;
      font-family: Avenir Medium;
    }

    .policyBackButton {
      cursor: pointer;
      text-align: center;
    }

    .button-container {
      width: 100%;
      text-align: center;
    }

    .complete_btn,
    .email_btn,
    .animation_next {
      min-height: 40px;
      padding: 1em 1.5em;
      width: auto;
      margin: 1em;
      background: var(--color_shape_blue);
      border: 1px solid var(--color_shape_blue);
      box-sizing: border-box;
      border-radius: 16px;
      color: var(--color_white);
      cursor: pointer;
      font-family: "Avenir Medium";
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
      text-transform: capitalize;
    }

    .info-wrapper {

      body {
        background-color: var(--color-primary_light_blue);
      }

      .info-wrapper .container {
        padding-bottom: initial;
      }

      .info_container {
        display: flex;
        align-items: center;
        justify-content: center;
        background: no-repeat url("/assets/images/Ellipse-single.svg") center center;
        background-size: contain;
      }

      .quiz_information {
        //padding: 22% 0;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 700px;
        max-width: 700px;
        padding: 0 1em;
        @media (max-height: 860px) {
          min-height: 600px;
        }
      }

      .information-text {
        margin: 0 5%;
        padding: 0 5%;
        text-align: center;
      }


      .header {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        line-height: 52px;
        letter-spacing: -0.8px;
      }

      .next_button {
        background: var(--color_shape_blue);
        border: 1px solid var(--color_shape_blue);
        color: var(--color_natural_gray);
        box-sizing: border-box;
        border-radius: 20px;
        padding: 1em 4em;
        margin-top: 1em;
        font-family: "Avenir";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 22px;
        float: right;
      }

      .info_button {
        width: 80%;
        background: var(--color_shape_blue);
        border: 1px solid var(--color_shape_blue);
        color: var(--color_natural_gray);
        box-sizing: border-box;
        border-radius: 20px;
        padding: 1em;
        margin: 1em;
        font-family: Avenir Medium;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.4px;
      }

      .custom_button {
        background: var(--color_shape_blue);
        border: 1px solid var(--color_dark_royal_blue);
        color: var(--color_natural_gray);
        box-sizing: border-box;
        border-radius: 40px;
        padding: 1em 4em;
        margin-top: 1em;
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
      }

      .info_button:focus {
        border-radius: 20px;
        outline: none;
        transition: 0.1s;
      }

      .subText {
        margin: 1rem;
      }

      .info_text {
        font-family: "Avenir";
        font-style: normal;
        font-weight: 800;
        font-size: 1.8rem;
        line-height: 33px;
        text-align: center;
        color: var(--color_Support-Basically-Black);
      }

      .postSubText {
        font-style: normal;
        font-size: 1rem;
        line-height: 33px;
        color: var(--color_Support-Basically-Black);
      }

      .postSubSmText {
        font-size: 1rem;
      }

      .text-bold {
        font-weight: 900;
      }

      .info_forward_button.blue {
        background-color: var(--color_shape_blue);
        color: white;
      }

      .info_forward_button {
        width: 125px;
        height: 40px;
        background-color: var(--color_light_gray);
        color: #212529;
        border: none;
        box-sizing: border-box;
        border-radius: 12px;
        float: right;
      }

      .sv_main .sv-action-bar {
        display: none
      }

      .custom-slide {
        margin: 10px;
        .subText {
          text-align: left;
          font-size: 40px;
          font-style: normal;
          font-weight: 800;
          line-height: 52px; /* 130% */
          letter-spacing: -0.8px;
          margin: 0px;
        }

        .info_text {
          margin-top: 1em;
          text-align: left;
          font-size: 32px;
          font-style: normal;
          font-weight: 700;
          line-height: 40px; /* 125% */
          letter-spacing: -0.64px;
        }

      }

      @media (max-width: 1440px) {
        // .quiz_information {
        //   min-height: 500px;
        //   max-width: 500px;
        // }
      }

      @media (max-width: 500px) {
        .info_container {
          background-size: cover;
        }

        .subText,
        .postSubText {
          font-size: 14px;
        }

        .info_text {
          font-size: 1.5rem;
        }

        // .quiz_information {
        //   min-height: 400px;
        //   max-width: 400px;
        // }
        .custom-slide {
          margin: 10px;
          .subText {
            font-size: 32px;
            font-style: normal;
            font-weight: 800;
            line-height: 40px; /* 125% */
            letter-spacing: -0.8px;
          }

          .info_text {
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 32px; /* 133.333% */
            letter-spacing: -0.48px;
          }

        }
      }
    }

    @media (max-width: 600px) and (max-height: 1000px) {
      .custom-postSubText {
        height: 500px;
      }
    }

    @media (max-width: 600px) and (max-height: 800px) {
      .custom-postSubText {
        height: 400px;
      }
    }

    @media (max-width: 600px) and (max-height: 700px) {
      .custom-postSubText {
        height: 300px;
      }
    }

    @media (max-width: 768px) {
      .imageWidth {
        width: 100%;
      }
    }

    @media (min-width: 768px) {
      .policyText {
        padding: 0 25px;
      }

      .policy_button {
        padding: 0 6em;
        margin-top: 2em;
      }
    }

    @media (max-width: 500px) {
      // .info-wrapper .info_button {
      //   width: auto;
      // }

      .info-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
        // height: 70vh;

        .header-text {
          color: var(--color_white);
          font-family: Avenir;
          // font-size: 32px;
          font-style: normal;
          font-weight: 800;
          line-height: 40px; /* 125% */
          letter-spacing: -0.64px;
        }

      }

    }


    .theme-no-bg {
      .information-text {
        font-size: 1.15em;
        padding: 0;
        margin: 0;
      }

      .info_container {
        background: none;
      }

      @media (max-width: 767px) {
        .subText, .postSubText {
          font-size: inherit;
        }
      }

      @media (min-width: 1440px) {
        .information-text {
          font-size: 1.25em;
          margin: 0 5%;
          padding: 0 5%;
        }

        .quiz_information {
          min-height: 700px;
          max-width: 700px;
        }
      }
    }
  }
}
