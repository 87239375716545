@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Black.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Black';
  src: url('/assets/fonts/Avenir-Black.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Medium.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Medium.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Book.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Book.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Book';
  src: url('/assets/fonts/Avenir-Book.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Book.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Avenir Medium";
  font-style: normal;
  font-weight: 500;
  src: url('/assets/fonts/Avenir-Medium.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Avenir';
  src: url('/assets/fonts/Avenir-Heavy.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url('/assets/fonts/Avenir-Heavy.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Heavy.woff') format('woff');
  font-weight: 800;
  font-style: normal;
}


@font-face {
  font-family: "Avenir-Regular";
  font-style: normal;
  font-weight: 400;
  src: url('/assets/fonts/Avenir-Book.woff2') format('woff2'),
      url('/assets/fonts/Avenir-Book.woff') format('woff');
}