.billing,
.payment-type,
.register {
  &.container-w-400 {
    max-width: 400px;
    margin: auto !important;
    width: 100% !important;

    @media screen and (min-width: 768px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
      gap: 35px !important;
    }

    &.card-container {
      .card-body {
        border-radius: 16px;
        border: 1px solid var(--color_support_light_grey);
      }
    }
  }
  &.padding-y-0 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

}
