#quiz-wrap {

  //iOS Scroll issues in-app browser [Mobile Quiz]
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  #surveyContainer {

    lottie-player {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 80vh;
    }
    .video-wrap {
      display: grid;
      justify-content: center;
      align-items: center;
      height: 70vh;
    }

    .video-animation {
      width: 100%;
      height: 90%;
    }
    .animation_next {
      margin: auto!important;
    }

    .sd-btn, .sd-root-modern  {
      font-family: Avenir Medium;
    }

    .sv-title-actions {
      // display: block;
      width: 100%;
    }
    .sd-description {
      font-family: Avenir Medium;
      color: var(--color_quiz_Black);
    }

    .sd-description:hover {
   //   cursor: pointer;
    }

    .sd-element--with-frame {
      background: none;
      box-shadow: none;
      padding-top: 0;
      margin-bottom: 2em;
    }

    .sd-root-modern {
      text-align: center;
      margin: auto;
      width: 100%;
      min-height: 100vh;
      padding-top: 1.5em;
      background-color: var(--color_quiz_background_body)!important;
    }

    .bg-blue {
      background-color:var(--color-primary_light_blue)!important;
    }

    .sd-container-modern {
      margin-top: 1.25em;
    }

    .sd-progress__bar {
      background-color: var(--color_shape_blue)!important;
      border-radius: 8px!important;
    }

    .sd-question__content {
      padding-top: 1em;
    }

    .sd-body__progress--top {
      text-align: center;
      width: 60%;
      margin: auto;
      border-radius: 8px!important;
      height: 0.5em!important;
    }

    .sd-progress__text {
      display: none;
    }

    .sd-page::-webkit-scrollbar {
      display: none; /* for Chrome, Safari, and Opera */
    }

    .sd-page {
      align-items: center!important;
      min-height: 80vh;
      // overflow: scroll;

      .sd-scrollable-container {
        margin: auto!important;
      }

      .sd-imagepicker {
        display: grid!important;
        grid-template-columns: repeat(2, 1fr);
      }

      .sd-imagepicker legend {
        display: none;
      }

      .sd-imagepicker__item--checked .sd-imagepicker__check-decorator {
        visibility: hidden;
      }

      .sd-question__required-text,
      .sd-radio__decorator,
      .sd-checkbox__decorator,
      .sd-expression {
        display: none;
      }


      .sd-selectbase__label {
        width: 100%;
      }
      .sd-item__control-label {
        text-align: center;
        font-family: Avenir Medium;
        font-weight: 500;
        border-radius: 8px!important;
        background: var(--color_white);
        border: 1px solid var(--color_med_light_grey)!important;
        color: var(--color_Support-Basically-Black);
        padding: 0.65em;
        //width: 70%!important;
        margin: auto;
        cursor: pointer;
      }

      .sd-item--checked .sd-item__control-label,
      .sd-imagepicker__item--checked {
        background-color: var(--color_shape_blue)!important;
        border: 2px solid var(--color_shape_blue)!important;
        color: var(--color_white)!important;
      }

      .sd-imagepicker__item img {
        background-color: transparent;
      }

      .sd-imagepicker__column {
        align-items: center;
      }

      .sd-imagepicker__image {
        width: 72px;
        height: 72px;
      }

      .sd-imagepicker__item {
        color: var(--color_quiz_Black);
        font-weight: 500;
        text-transform: capitalize;
        padding: 1em;
        border: 1px solid var(--color_med_light_grey);
        background-color: var(--color_white);
        border-radius: 8px;
        width: 100%;
        //height: 150px;
        text-align: center;
      }

      .sd-input,
      .sd-multipletext__item-container {
        background-color: var(--color_white);
        font-family: 'Avenir Medium';
      }

      .sd-multipletext__item-container.sd-input:focus-within {
        box-shadow: inset 0 0 0 1px var(--color_shape_blue), inset 0px 1px 2px var(--border-inside, rgba(0, 0, 0, 0.16));
      }

      .sd-input:focus {
        box-shadow: none;
      }

      .sd-element__title .sv-string-viewer {
        white-space: normal;
        font-size: 1.5rem;
        line-height: 33px;
        font-weight: 800;
        font-family: "Avenir Black";
      }

      .sd-imagepicker__item--checked .sd-imagepicker__item-decorator .sd-imagepicker__text {
        color: var(--color_white)!important;
      }
      .sd-imagepicker__item--checked .sd-imagepicker__image-container .sd-imagepicker__image {
        filter: brightness(0) invert(1);
      }

      @media (max-width: 500px) {
        .sd-imagepicker {
          grid-template-columns: auto!important;
        }
        [data-name="gender"] .sd-imagepicker {
          grid-template-columns: repeat(2, 1fr);
        }
      }

    }

    .sd-page__title {
      color: var(--color-quiz_PrimaryGrays);
      font-family: "Avenir Black";
      font-size: 1.15rem;
      font-weight: 500;
    }
    @media (max-width: 500px) {

      .sd-page__title {
        font-size: 1rem;
      }

    }

    .sd-btn:not(:disabled):hover,
    .sd-btn:not(:disabled):focus {
      box-shadow: 0 0 0 2px var(--color_shape_blue) !important;
    }

    .sd-btn {
      color: var(--color_shape_blue);
      border-radius: 8px!important;
      border: 1px solid var(--color_shape_blue);
    }
    .sd-navigation__next-btn,
    .sd-navigation__prev-btn {
      width: 150px;
      padding-left: 0!important;
      padding-right: 0!important;
    }
    .sd-navigation__next-btn,
    .sd-navigation__prev-btn,
    .sd-navigation__complete-btn {
      background: var(--color_shape_blue);
      border: 1px solid var(--color_dark_royal_blue);
      color: var(--color_white);
      border-radius: 40px!important;
    }

    .sd-action-bar {
      justify-content: center!important;
      bottom: 0;
      position: -webkit-sticky;
      position: sticky;
      background-color: var(--color_quiz_background_body)!important;
    }

    .lottie-video video {
      width: 80%;
    }
    .lottie-video {
      position: absolute;
      bottom: 10%;width:
      fit-content;
      left: 0;
      right: 0;
      margin: auto;
      z-index: 9999;
      height: 40%;
    }
    @media (max-width: 1040px) {

      .lottie-video video {
        height: 80%;
      }

    }
    @media (max-width: 500px) {

      .sd-root-modern,
      .sd-page .sd-item__control-label {
        width: 100%;
      }

      .lottie-video video {
        height: 60%;
        width: 60%;
      }

    }

    .sd-element--with-frame>.sd-question__erbox--above-question {
      margin-top: 0!important;
    }

    .phone_title {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &_text {
        white-space: normal;
        font-size: 1.5rem;
        line-height: 33px;
        font-weight: 800;
        font-family: "Avenir Medium";
      }

      &_subtitle {
        white-space: normal;
        font-size: 20px;
        line-height: 33px;
        font-family: "Avenir Medium";
      }
    }

    .phone_navigation {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      gap: 1em;

      p {
        margin-bottom: 0;
      }

      &_stop_text {
        align-self: stretch;
        position: relative;
        font-size: 18px;
        text-align: center;
      }

      &_opt_out {
        font-size: 18px;
        text-decoration: underline;
        line-height: 24px;
        font-weight: 800;
        font-family: Avenir;
        color: var(--color_shape_blue);
        text-align: center;
        cursor: pointer;
      }
    }

    [data-name="phone_number"] {

      margin-bottom: 0 !important;
      padding: 0 !important;

      .sd-element__header {
        display: none;
      }

      .sd-question__content {
        padding-top: 0;
      }

    }

    [data-name="phone_terms"] {

      margin-bottom: 0 !important;
      padding: 0 !important;

      .sd-item__control:focus+.sd-item__decorator {
        box-shadow: none;
      }

      .sd-checkbox__decorator {
        display: block;
      }

      .sd-item--checked .sd-item__decorator {
        background-color: var(--color_shape_blue) !important;
        border: 2px solid var(--color_shape_blue) !important;
        color: var(--color_white) !important;
        box-shadow: none;
        cursor: pointer;
      }

      .sd-item--checked .sd-item__control-label {
        background: var(--color_white) !important;
        border: none !important;
        color: var(--color_Support-Basically-Black) !important;
      }

      .sd-selectbase__label {
        background: var(--color_white);
        color: var(--color_Support-Basically-Black);
        border-radius: 16px !important;
        padding: 1em;
        cursor: pointer;
      }

      .sd-item__decorator {
        background: var(--color_white);
        border: 1px solid var(--color_med_light_grey) !important;
        color: var(--color_Support-Basically-Black);
      }

      .sd-item__control-label {
        text-align: left;
        font-family: Avenir Medium;
        font-weight: 500;
        background: var(--color_white);
        margin: auto;
        border: none !important;
        padding: 0;
        font-size: 14px;
        cursor: pointer;
      }

      .sd-checkbox--checked .sd-checkbox__svg use {
        fill: var(--color_white) !important;
      }
    }

  }
}


