.exit-modal-container {
    .mat-dialog-container {
        border-radius: 24px;
        padding: 16px;
        max-width: 650px;
    }

    .exit-dialog-content {
        font-family: Avenir;
        font-style: normal;
        font-weight: 800;
        font-size: 1.25em;
        line-height: 31px;
        text-align: center;
        color: #0B090A;
        margin: 24px 0px;
        overflow-y: hidden;
    }

    .mat-dialog-actions {
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: initial;
    }

    .exit-dialog-btn {
        padding: 12px 40px;
        width: 180px;
        height: auto;
        border-radius: 46px;
        border: none;
        outline: none;
        color: #AEB5BC;
        background: #F8F9FA;
        margin-bottom: 10px;
        cursor: pointer;
    }

    .exit-dialog-btn--primary {
        background: var(--color_shape_blue);
        color: #FFFFFF;

        &:hover {
            background: #0036A5;
        }
    }

    @media (min-width: 768px) {
        .mat-dialog-container {
            border-radius: 24px;
            padding: 48px 32px;
        }

        .exit-dialog-content {
            font-size: 24px;
        }

        .exit-dialog-btn {
            // margin-bottom: initial;
            margin: 0px 10px;
        }

    }
}

.loading-modal-container {
    .mat-dialog-container {
        border-radius: 24px;
        padding: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        min-height: 250px;
        min-width: 250px;
    }

    .mat-progress-spinner {
        margin: 0 auto;
    }

    .loading-text {
        font-family: "Avenir";
        font-style: normal;
        font-weight: 800;
        font-size: 1.5em;
        text-align: center;
        color: #0B090A;
        margin-top: 25px;
        overflow: hidden;
    }

    @media (min-width: 992px) {
        .mat-dialog-container {
            min-height: 300px;
            min-width: 300px;
        }
    }
}