.button {
  color: var(--color_white);
  font-size: 16px;
  padding: 16px 70px;
  border-radius: 8px;
  text-decoration: none;
  @media (min-width: 768px) {
    font-size: 20px;
    padding: 16px 32px;
  }
  &.cta {
    background-color: var(--color_shape_blue);
    padding: 16px 50px;
    &:hover {
      border: none;
    }
    &.dark {
      background-color: var(--color_shape_mid_blue);
    }
  }
}
