.recurly-element-cvv,
.recurly-element-month,
.recurly-element-year,
.recurly-hosted-field-cvv,
.recurly-hosted-field-month,
.recurly-hosted-field-year{
  width:6em
}
#recurly-element-month,
#recurly-element-year,
#recurly-element-cvv,
#recurly-element-number {
  border-radius: 4px;
  border: 1px solid var(--color_dark_black);
  font-family: "Avenir Medium";
  font-size: 12px;
  color: var(--color_soft_black);
  margin-bottom: 10px;
}
.recurly-month-year {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 1rem;
  flex: 1 1 auto;
  justify-content: flex-start;
}
.input-wrap-pay-year {
  margin-left: 10px;
}
.wrap-month-year-cvv {
  display: flex;
  flex-direction: row;
}
.recurly-cvv {
  justify-content: flex-end;
}
.recurly-element,.recurly-hosted-field{
  border-radius:3px;
  height:40px;
  box-sizing:border-box;
  padding: 0 8px;
}
.recurly-element-focus,.recurly-hosted-field-focus{
  border:1px solid var(--color_light_dark_black);
}
.recurly-element-invalid,.recurly-hosted-field-invalid{
  border:1px solid var(--color_dark_red);
}
