#quiz-wrap {
  #surveyContainer {
    .fairy_funnel {
      .sd-page {
        justify-content: center;
        padding: 0 10px !important;
      }
      .sd-element--with-frame {
        padding: 0 5px !important;
      }
      .sd-page .sd-checkbox__decorator {
        display: block;
      }
      .sd-item--checked .sd-item__decorator {
        background: var(--color_shape_blue)!important;
        border: none !important;
      }
      .sd-item__control:focus+.sd-item__decorator {
        box-shadow: none !important;
      }

      .sd-page .sd-item__control-label {
        padding: 10px;
        margin-bottom: 8px;
      }
      .sd-page .sd-item {
        padding: 0;
      }

      .sd-page .sd-checkbox {
        text-align: center;
        font-family: Avenir Medium;
        font-weight: 500;
        border-radius: 16px!important;
        background: var(--color_white);
        border: 1px solid var(--color_med_light_grey)!important;
        color: var(--color_Support-Basically-Black);
        padding: 12px;
        margin-bottom: 8px;
        cursor: pointer;

        .sd-item__control-label {
          padding: 0 !important;
          border: none !important;
          background: none !important;
          margin: 0;
        }
      }
      .sd-checkbox--checked .sd-checkbox__svg {
        width: 100%;
        height: 100%;
      }
      .custom-two-col-checkbox {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 10px;
      }

      .sd-page .sd-item__control-label {
        border-radius: 16px !important;
      }

      .sd-page .sd-radio--checked .sd-item__control-label {
        background-color: var(--color-primary_light_blue)!important;
        border: 2px solid var(--color_shape_blue)!important;
      }

      .sd-page .sd-checkbox--checked {
        background-color: var(--color-primary_light_blue)!important;
        border: 2px solid var(--color_shape_blue)!important;
        color: var(--color_shape_blue) !important;
      }

      .sd-page .sd-item--checked .sd-item__control-label {
        color: var(--color_shape_blue) !important;
      }

      .sd-element--with-frame,
      .sd-checkbox .sd-item__control-label {
        text-align: start;
      }
    }
  }
}
