@import "./fonts";
@import "./color";
@import "./material";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "./btn.scss";
@import "./components/survey/survey.scss";
@import "./components/challenge/challenge-variations.scss";
@import "./components/quiz.scss";
@import "./components/recurly.scss";
@import "./components/video-player.scss";
@import "./components/material-dialog.scss";
@import "./components/payment.scss";

body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-height: 100%;
}

html {
  scroll-behavior: smooth;
}
/*Common CSS across application*/
body {
  background-color: var(--color_quiz_background_body);
}
.blue_highlight_text {
  color: var(--color_shape_blue);
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';

.avenir-black-white-12px {
  color: var(--white);
  font-family: var(--font-family-avenir-black);
  font-size: var(--font-size-s);
  font-weight: 900;
  font-style: normal;
}
.avenir-heavy-normal-blue-32px {
  color: var(--blue);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-xl2);
  font-weight: 400;
  font-style: normal;
}
.avenir-regular-normal-cod-gray-18px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-regular);
  font-size: var(--font-size-m);
  font-weight: 400;
  font-style: normal;
}
.avenir-heavy-normal-blue-48px {
  color: var(--blue);
  font-family: var(--font-family-avenir-heavy);
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
}
.avenir-roman-normal-cod-gray-24px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-roman);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
}
.avenir-heavy-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
}
.avenir-roman-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-avenir-roman);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.avenir-heavy-normal-white-20px {
  color: var(--white);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.avenir-medium-white-18px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-blue-18px {
  color: var(--blue);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
}
.avenir-regular-normal-cod-gray-24px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
}
.avenir-heavy-normal-cod-gray-32px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-heavy);
  font-size: var(--font-size-xl2);
  font-weight: 400;
  font-style: normal;
}
.avenir-roman-normal-cod-gray-20px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-roman);
  font-size: var(--font-size-l);
  font-weight: 400;
  font-style: normal;
}
.avenir-regular-normal-cod-gray-48px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-regular);
  font-size: 48px;
  font-weight: 400;
  font-style: normal;
}
.avenir-roman-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-avenir-roman);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
}
.avenir-black-cod-gray-56px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-black);
  font-size: 56px;
  font-weight: 900;
  font-style: normal;
}
.avenir-medium-white-56px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: 56px;
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-white-40px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xxl2);
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-cod-gray-40px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xxl2);
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-white-10px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
}
.avenir-regular-normal-salmon-pearl-24px {
  color: var(--salmon-pearl);
  font-family: var(--font-family-avenir-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
}
.avenir-medium-cod-gray-32px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xl2);
  font-weight: 500;
  font-style: normal;
}
.avenir-regular-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-avenir-regular);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.avenir-heavy-normal-cod-gray-72px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-heavy);
  font-size: 72px;
  font-weight: 400;
  font-style: normal;
}
.avenir-book-normal-white-12px {
  color: var(--white);
  font-family: var(--font-family-avenir-book);
  font-size: var(--font-size-s);
  font-weight: 400;
  font-style: normal;
}
.avenir-medium-white-48px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: 48px;
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-cod-gray-10px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xs);
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-cod-gray-18px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-m);
  font-weight: 500;
  font-style: normal;
}
.avenir-regular-normal-white-24px {
  color: var(--white);
  font-family: var(--font-family-avenir-regular);
  font-size: var(--font-size-l2);
  font-weight: 400;
  font-style: normal;
}
.avenir-medium-salmon-pearl-24px {
  color: var(--salmon-pearl);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-l2);
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-white-16px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: 16px;
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-cod-gray-48px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: 48px;
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-cod-gray-56px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: 56px;
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-white-32px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-xl2);
  font-weight: 500;
  font-style: normal;
}
.avenir-medium-cod-gray-24px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-l2);
  font-weight: 500;
  font-style: normal;
}
.avenir-roman-normal-cod-gray-32px {
  color: var(--cod-gray);
  font-family: var(--font-family-avenir-roman);
  font-size: var(--font-size-xl2);
  font-weight: 400;
  font-style: normal;
}
.avenir-medium-white-24px {
  color: var(--white);
  font-family: var(--font-family-avenir-medium);
  font-size: var(--font-size-l2);
  font-weight: 500;
  font-style: normal;
}

/* Default NGX Skeleton */
.content-loading {
  margin: 50px auto;
  max-width: 75%;
}