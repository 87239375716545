#quiz-wrap {
  .mySlide-img {
    vertical-align: middle;
    width: 300px;
    height: 300px;
    border-radius: 25px;
  }

  .mySlide-img-text {
    font-family: 'Avenir Medium';
    font-size: 20px;
    margin-top: 0.5em;
    line-height: 27.32px;
    color: var(--color_Support-Gray);
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: center;
  }

  .slide-navigation {
    width: 20px;
    height: 25px;
  }

  .mySlide-img-active {
    border: 3px solid var(--color_shape_blue);
  }

  /* Caption text */
  .text {
    font-size: 15px;
    bottom: 8px;
    width: 100%;
    text-align: center;
  }

  .slider-dot-container {
    text-align: center;
  }

  /* The dots/bullets/indicators */
  .dot {
    cursor: pointer;
    height: 15px;
    width: 15px;
    //background-color: var(--color_shape_blue);
    background: url("/assets/images/quiz/round-blue-tick.png");
    background-repeat: no-repeat;
    background-size: 15px 15px;
    background-position-x: center;
    background-position-y: center;
    display: inline-block;
    margin: 0;
    padding: 0;
  }
  .line{
    height: 3px;
    width: 40px;
    background-color: var(--color_shape_blue);
    display: inline-block;
    margin: 6px 0;
    padding: 0;
  }
  .line:last-child {
    clear: both;
    background-color: transparent;
  }

  .dot.active {
    height: 20px;
    width: 20px;
    background: url(/assets/images/quiz/Icon-Slider-Selected.png);
    background-repeat: no-repeat;
    background-size: 20px 20px;
    display: inline-block;
    margin: 0;
    padding: 0;
  }

  /* Fading animation */
  .fade {
    animation-name: fade;
    animation-duration: 1.5s;
  }

  @keyframes fade {
    from {opacity: .4}
    to {opacity: 1}
  }

  .slider-wrapper {
    margin: auto;
    position: relative;
    overflow: hidden;
    width: 600px;
  }

  .slides-container {
    width: 80%;
    margin: auto;
    display: flex;
    overflow: auto;
    scroll-behavior: smooth;
    list-style: none;
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  .slides-container::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .slides-container {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .slide-arrow {
    position: absolute;
    display: flex;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 5rem;
    background-color: transparent;
    border: none;
    width: 2rem;
    font-size: 3rem;
    padding: 0;
    cursor: pointer;
  }

  .slide-arrow:hover,
  .slide-arrow:focus {
    opacity: 1;
  }

  #slide-arrow-prev {
    left: 0em;
    padding-left: 0.25rem;
    border-radius: 0 2rem 2rem 0;
  }

  #slide-arrow-next {
    right: 0em;
    padding-left: 0.75rem;
    border-radius: 2rem 0 0 2rem;
  }
  .grey-scale {
    filter: grayscale(100%);
    opacity: 0.5;
  }

  .slide {
    width: 100%;
    height: 100%;
    padding: 1em;
  }
  .custom_navigation-carousel {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    border-top: 1px solid var(--color_med_light_grey);
    margin: 1em 1em 0;
    padding-top: 1em;

    &-previous {
      display: flex;
      padding: 1em 2em;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      border: none;
      color: var(--color_Black);
      background-color: transparent;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    &-previous:hover {
      border-radius: 16px;
      background-color: var(--color_white);
    }

    &-next {
      display: flex;
      padding: 1em 2em;
      justify-content: center;
      align-items: center;
      border-radius: 16px;
      background: var(--color-quiz_primaryBlue);
      border: none;
      color: #fff;
      gap: 5px;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      letter-spacing: -0.4px;
    }

    &-next:hover{
      background: var(--color_shape_blue);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }

    @media (max-width: 500px) {
      position: fixed;
      padding: 1em;
      bottom: 0px;
      background: var(--color_quiz_background_body);
    }

  }

  @media only screen and (max-width: 500px) {
    .custom_navigation-carousel {
      width: 100vw;
      left: -16px;
    }
    .slider-wrapper {
      width: 325px;
    }

    .mySlide-img {
      width: 200px;
      height: 200px;
    }

    .mySlide-img-text {
      font-size: 16px;
      margin-top: 0.5em;
    }

    .line {
      width: 30px;
    }
  }
  @media only screen and (max-width: 400px) {
    .slider-wrapper {
      width: 300px;
      height: 270px;
    }

    .mySlide-img {
      width: 150px;
      height: 150px;
    }
  }
  @media only screen and (max-width: 360px) {
    .line {
      width: 20px;
    }
  }
}
