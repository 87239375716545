.challenge-variation {
  --challenge-green: var(--Success-600, #2EAA3D);

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 80vh;
  // padding: 2em;
  padding-top: 25px;

  font-family: Avenir;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.36px;
  color: var(--color_white);

  &-page-bg-blue {
    background: #D5EBFE !important;
  }

  &-page-bg-dark-blue {
    background:  #3078EB;
  }

  &>* {
    margin-top: 25px;
    max-width: 100%;

  }

  &-banner-image {
    width: 50%;
    height: 200px;
  }

  &-blue-texture-bg {
    background-image: url('/assets/images/challenge-variation-bg.png');
    overflow-x: hidden;
  }

  &-blue-texture-bg-curve {
    background-image: url('/assets/images/CurveVector.svg'), url('/assets/images/challenge-variation-bg.png');
    background-position: center, left top;
    background-repeat: no-repeat, repeat;
  }

  &-blue-curve {
    background-image:url('/assets/images/CurveVector.svg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  &-blue-bg {
    border-radius: 16px;
    background: var(--Primary-primary-700, #1844A9);
    padding: 24px;
  }

  &-dark-blue-bg {
    border-radius: 24px;
    background-color: #15357d;
    padding: 24px;
  }

  &-bg-blue {
    border-radius: 20px;
    background:  #3078EB;
    padding: 20px;
  }

  &-bg-light-blue {
    border-radius: 15px;
    background: rgba(48, 120, 235, 0.16);
    padding: 20px;
    align-items: center;
  }

  &-bg-lighter-blue {
    border-radius: 20px;
    background: rgba(48, 120, 235, 0.08);
    padding: 20px;
    align-items: center;
  }

  &-dark-blue-bg-opaque {
    background: var(--Support-transparent-24, rgba(0, 7, 45, 0.24));
    padding: 24px;
  }

  &-light-blue-bg-gradient {
    background: linear-gradient(192deg, #D5EBFE -5.53%, #619FF3 107.77%);
    text-align: center;
    padding: 24px;
  }

  &-white-bg {
    border-radius: 24px;
    background: var(--Grays-white, #FFF);
    padding: 24px;
  }

  &-in-product-list li, &-fav-part li {
    list-style: none;
    display: flex;
  }

  &-in-product-list {
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    text-align: left;
    list-style: none;

    li {
      color: #0B090A;
      font-family: Avenir;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px; /* 120% */
      padding-bottom: 1em;
    }

    .checkFat {
      width: 32px;
      height: 32px;
      padding-right: 10px;
      align-items: center;
    }
  }

  &-fav-part {
    margin-bottom: 0px !important;
    padding-left: 0px !important;
    list-style: none;

    .heart {
      width: 36px;
      height: 36px;
      flex-shrink: 0;
    }
  }

  /** Buttons **/
  .challenge-btn,
  .submit-btn {
    border-radius: 16px;
    background-color: #DD3F4A;
    color: white;
    width: 100%;
    padding: 12px;
    border: 1px solid #DD3F4A;
    cursor: pointer;
  }

  .submit-btn:not(:disabled),
  .challenge-btn {
    background-color: var(--challenge-green);
    border: 1px solid var(--challenge-green);
  }

  .challenge-btn-white {
    border-radius: 40px;
    background: var(--Grays-white, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.70);
    color: var(--Basic-basic-800, #172032);
  }

  &-width-sm {
    max-width: 370px;
  }

  &-width-md {
    max-width: 625px;
  }

  &-width-full {
    width: 100%;
    border-radius: 0;
  }

  .text-gray {
    color: var(--Support-Grays-DFE2E6, #DFE2E6);
  }

  .text-white {
    color: var(--color_white);
  }

  .text-dark-blue {
    color: #343A40;
  }

  .text-blue {
    color: #1844A9;
  }

  .text-darker-blue {
    color: #092070;
  }

  .text-black {
    color: #000;
  }

  .no-bold {
    font-weight: normal;
  }

  .wt-600 {
    font-weight: 600;
  }

  &-text-50 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    font-size: 50px;
    font-weight: 900;
    line-height: 64px;
    /* 128% */
    letter-spacing: -1px;
  }

  &-text-40 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    font-size: 40px;
    font-weight: 900;
    line-height: 56px;
    /* 140% */
    letter-spacing: -0.8px;
  }

  &-text-35 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    text-align: center;
    font-size: 35px;
    font-weight: 800;
    line-height: 48px;
    /* 137.143% */
    letter-spacing: -0.7px;
  }

  &-text-28 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    text-align: center;
    font-size: 28px;
    font-weight: 800;
    line-height: 32px;
    /* 123.077% */
    letter-spacing: -0.52px;
  }

  &-text-26 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    text-align: center;
    font-size: 26px;
    font-weight: 800;
    line-height: 32px;
    /* 123.077% */
    letter-spacing: -0.52px;
  }

  &-text-25 {
    font-family: Avenir;
    font-size: 25px;
    color: var(--color_white);
    font-style: normal;
    font-weight: 800;
    line-height: 32px;
    /* 128% */
    letter-spacing: -0.5px;
  }

  &-text-23 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    font-size: 23px;
    font-weight: 800;
    line-height: 32px;
    /* 139.13% */
    letter-spacing: -0.46px;
  }

  &-text-22 {
    font-family: Avenir;
    font-style: normal;
    color: var(--color_white);
    font-size: 22px;
    font-weight: 400;
    line-height: 24px;
    /* 109.091% */
    letter-spacing: -0.44px;
  }

  &-text-gray-20 {
    color: #ACD4FD;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 800;
    line-height: 24px; /* 120% */
  }

  &-text-white-20 {
    color: #FFF;
    font-family: Avenir;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px; /* 120% */
    text-align: left;
  }

  &-timer-width {
    width: 60%;
    margin: auto;
    text-align: center;
  }

  &-content,
  &-content-4 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    color: var(--color_white);
    margin-top: 1em;
    gap: 25px;

    &-text {
      display: flex;
      flex-direction: column;
      margin: auto;
      text-align: left;
      margin: 12px;

      &-list {
        display: grid;
        grid-template-columns: 1fr 1fr;

        p {
          margin-bottom: 0px;
        }
      }
    }

    &-signup {
      &-bgc {
        border-radius: 24px;
        background: rgba(0, 7, 45, 0.40);
        backdrop-filter: blur(16px);
        text-align: center;
        margin: auto;
        padding: 20px;
      }
    }
  }

  &-content-4 {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    width: 78%;
    margin: 30px auto;

    &>* {
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }

  &-content-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: center;
    justify-content: center;
    padding: 1em;
    gap: 20px;
    margin: auto;
  }

  &-list-border {
    border: 3px solid #fff;
    padding: 1em;
    border-radius: 30px;
  }

  .challenge-list-flex-30 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 30px;

    .challenge-list-item {
      display: flex;
      justify-content: center;
      gap: 15px;
      flex-basis: 30%;
      text-align: left;
      padding: 15px;

      p {
        margin-bottom: 0;
      }
    }

    .challenge-list-item> :first-child {
      text-align: center;
      font-family: Avenir;
      font-size: 50px;
      font-style: normal;
      font-weight: 900;
      line-height: 64px;
      letter-spacing: -1px;
    }
  }


  .challenge-list-none {
    list-style-type: none;
    text-align: left;
    padding-left: 0;

    &>* {
      margin-bottom: 25px;

      display: flex;
      gap: 25px;
    }
  }

  .list-circles {
    counter-reset: li;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      list-style: none;
      margin: 1em 0;
      padding-left: 2.25em;
      position: relative;
      text-align: left;
    }

    li::after {
      counter-increment: li;
      content: counter(li);
      position: absolute;
      font-size: 0.9em;
      left: 0;
      font-weight: 600;
      top: -0.25em;
      width: 1.95em;
      height: 1.95em;
      background-color: transparent;
      color: #fff;
      border: 1px solid;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 992px) {
    & > *:not(:last-child) {
      margin: 15px;
    }

    &-timer-width {
      width: 100%;
    }

    &-text-50 {
      font-size: 36px;
      line-height: 48px;
      /* 133.333% */
      letter-spacing: -0.72px;
    }

    &-text-40 {
      font-size: 32px;
      line-height: 40px;
      /* 125% */
      letter-spacing: -0.64px;
    }

    &-text-28 {
      font-size: 20px;
      line-height: 32px;
      /* 139.13% */
      letter-spacing: -0.46px;
    }

    &-text-35 {
      font-size: 23px;
      line-height: 32px;
      /* 139.13% */
      letter-spacing: -0.46px;
    }

    &-text-white-20, &-text-gray-20 {
      font-size: 16px;
      line-height: 24px; /* 150% */
    }

    &-content,
    &-content-4 {
      grid-template-columns: 1fr;

      &-text {
        &-list {
          grid-template-columns: 1fr;
          text-align: center;
        }
      }
    }

    .challenge-list-flex-30 {
      .challenge-list-item {
        flex-grow: 1
      }
    }
  }

  @media screen and (max-width: 767px) {

    &-banner-image {
      width: 100%;
    }

    &-in-product-list {
      margin-top: 10px;
    }

    &-blue-curve {
      background-size: auto 100%;
      background-size: 800%;
    }

    .floating-btn {
      position: fixed;
      bottom: 10px;
      z-index: 10;
      left: auto;
      right: auto;
    }

    .challenge-btn,
    .floating-btn {
      width: 97%;
      margin: 0 auto;
    }

    .challenge-list-flex-30 {
      width: 75%;

      .challenge-list-item {
        justify-content: flex-start;
        flex-basis: 100%;
      }
    }

    &-content-2 {
      grid-template-columns: 1fr;
    }
  }

  @media screen and (min-width: 992px) {
    &-width-lg {
      max-width: 1024px;
    }
  }

  @media screen and (min-width: 1400px) {
    &-width-xl {
      max-width: 1300px;
    }

    &-width-xxl {
      max-width: 1500px;
    }
  }
}
